import React, { memo } from 'react';
import { Router, DefaultRoute } from '@reach/router';
import { PrivateRoute, LoginStatus, Card } from '@components';
import { Login, CovidForm, ControlPanel } from '@containers';

import './employee.scss';
import { Link } from 'gatsby';
const Employee = () => (
  <div>
    <div>
      <div className="on-employee-top">
        <div className="title">
          <h2>Employee area</h2>
          {<LoginStatus />}
        </div>
      </div>
      <div className="on-employee-main">
        <Router basepath="/employee">
          <Login path="/login" />
          <ControlPanel path="/" />
          <PrivateRoute path="/dashboard" component={ControlPanel} />
          <PrivateRoute path="/covid" component={CovidForm} />
        </Router>
      </div>
    </div>
  </div>
);

export default memo(Employee);
